// Generated file - do not edit directly
export const accordionStyles = `.p-accordionpanel:not(.p-disabled).p-accordionpanel-active > .p-accordionheader {
  font-weight: 600;
  border-bottom: 0;
}

.p-accordionheader {
  justify-content: flex-end;
}`;

export const avatarStyles = `p-avatar span {
  color: white;
}`;

export const buttonStyles = `.p-button-text .p-button-label {
  font-weight: normal;
  font-size: 0.875rem;
}`;

export const chipsStyles = `.p-iconwrapper {
  margin: 0 0.4rem 0 0.4rem;
}

.p-chip {
  max-height: 38px;
}`;

export const datepickerStyles = `.p-inputtext {
  height: 38px;
}

.p-datepicker-calendar .p-button-icon-only {
  color: #6c757d !important;
}

.p-datepicker-day-view {
  font-size: 14px;
}

.p-datepicker-prev-button:hover,
.p-datepicker-next-button:hover {
  background: transparent !important;
}`;

export const inputgroupStyles = `p-inputgroupaddon > i {
  height: 20px;
  width: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}`;

export const inputtextStyles = `.p-inputtext {
  font-weight: 600;
  font-size: 14px;
}`;

export const multiselectStyles = `.p-multiselect {
  height: 38px;
}

.p-multiselect-label {
  font-weight: 600;
}

.p-multiselect.p-disabled {
  opacity: 0.7;
}

.p-multiselect.custom-narrow {
  width: 240px;
}

.p-multiselect.custom-narrow .p-multiselect-label.p-placeholder {
  color: #a9a9a9;
  font-weight: 400;
}`;

export const selectStyles = `.p-select {
  height: 38px;
  max-width: 280px;
  width: 100%;
}

.p-select-label {
  font-weight: 600;
}

.p-select.p-disabled {
  opacity: 0.7;
}

.p-inputfield-sm {
  height: 34.375px;
}
.p-inputfield-sm span {
  font-size: 0.765625rem !important;
}`;

export const stepperStyles = `.p-steppanel {
  display: none;
}

.p-steppanel-active {
  display: initial;
}

p-step-panels p-stepper-separator {
  display: none;
}

.p-stepper-number {
  width: 2.5rem;
  height: 2.5rem;
}

.p-stepper-title {
  margin-right: 1rem;
}`;

export const tabsStyles = `.p-tab-active {
  border-bottom-width: 2px;
  border-top-width: 0;
}

.p-tabs,
.p-tabpanels,
.p-tabpanel {
  height: 100%;
}

.p-tabs.router-tabs {
  height: auto;
  margin: 0 0 1.75rem 0;
}`;

export const togglebuttonStyles = `.p-togglebutton {
  border-radius: 0;
  height: 38px;
  font-size: 0.765625rem;
}

.p-togglebutton.icon-only {
  padding: 0;
}

.p-togglebutton.p-togglebutton-checked.p-disabled {
  background: var(--p-perspio-main-color);
  color: white;
  opacity: 0.7;
}`;

export const toggleswitchStyles = `.p-toggleswitch.p-disabled .p-toggleswitch-slider {
  opacity: 0.7;
}`;
