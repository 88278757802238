import { createAction, props } from '@ngrx/store';
import {
  LOAD_WEATHER_ALERTS,
  LOAD_WEATHER_ALERTS_ERROR,
  STORE_WEATHER_ALERTS,
} from './action-types';

export const loadWeatherAlertsData = createAction(LOAD_WEATHER_ALERTS);
export const loadWeatherAlertsSuccess = createAction(
  STORE_WEATHER_ALERTS,
  props<{ payload: any[] }>()
);
export const loadWeatherAlertsFailure = createAction(
  LOAD_WEATHER_ALERTS_ERROR,
  props<{ error: any }>()
);
