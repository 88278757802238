import { STORE_DOWNLOAD_REPORT_TASKS } from '../actions/action-types';

const initialState: any = {
  data: null,
};

export function downloadReportTasksReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_DOWNLOAD_REPORT_TASKS: {
      return { data: action.payload };
    }

    default: {
      return state;
    }
  }
}
