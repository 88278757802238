import {
  STORE_WEATHER_ALERTS,
  STORE_WEATHER_ALERTS_ERROR,
} from '../actions/action-types';

const initialState: WeatherAlertsState = {
  weatherAlerts: null,
  hasError: null,
};

export function weatherAlertsReducer(
  state = initialState,
  action
): WeatherAlertsState {
  switch (action.type) {
    case STORE_WEATHER_ALERTS:
      return {
        hasError: false,
        weatherAlerts: action.payload,
      };

    case STORE_WEATHER_ALERTS_ERROR:
      return {
        hasError: true,
        weatherAlerts: null,
      };

    default: {
      return state;
    }
  }
}
