import { BadgeDesignTokens } from '@primeng/themes/types/badge';

export const BADGE_TOKENS = {
  paddingY: '0.5rem',
  colorScheme: {
    light: {
      primary: {
        background: '{perspio-main-color}',
      },
    },
  },
} as BadgeDesignTokens;
