import { STORE_REGIONS, STORE_REGIONS_ERROR } from '../actions/action-types';

const initialState: RegionsData = {
  regions: null,
  hasError: null,
};

interface RegionsData {
  regions: any[];
  hasError: boolean;
}

export function regionsReducer(state = initialState, action): RegionsData {
  switch (action.type) {
    case STORE_REGIONS:
      return {
        hasError: false,
        regions: action.payload,
      };

    case STORE_REGIONS_ERROR:
      return {
        hasError: true,
        regions: null,
      };

    default: {
      return state;
    }
  }
}
