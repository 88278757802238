import { Routes } from '@angular/router';
import { ROUTES_CLIENT_APPS } from './components/client-apps/client-apps-routing.config';
import { ROUTES_PROFILES } from './components/profiles/profiles-routing.config';

export const ROUTES_ADMIN: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/admin/admin.component').then((m) => m.AdminComponent),
    children: [
      { path: '', redirectTo: 'organisation', pathMatch: 'prefix' },
      {
        path: 'tenant',
        loadComponent: () =>
          import('./components/tenant/tenant.component').then(
            (m) => m.TenantConfigurationComponent
          ),
      },
      {
        path: 'organisation',
        loadComponent: () =>
          import('./components/tenant-info/tenant-info.component').then(
            (m) => m.TenantInfoComponent
          ),
      },
      {
        path: 'audit-log',
        loadComponent: () =>
          import('./components/audit/audit.component').then(
            (m) => m.AuditComponent
          ),
      },
      {
        path: 'users',
        loadComponent: () =>
          import('./components/users/users.component').then(
            (m) => m.UsersComponent
          ),
      },
      {
        path: 'zones',
        loadComponent: () =>
          import('./components/zones/zones.component').then(
            (m) => m.ZonesComponent
          ),
      },
      {
        path: 'tokens',
        loadComponent: () =>
          import('./components/tokens/tokens.component').then(
            (m) => m.TokensComponent
          ),
      },
      {
        path: 'billing',
        loadComponent: () =>
          import('./components/billing/billing.component').then(
            (m) => m.BillingComponent
          ),
      },
      {
        path: 'system-events',
        loadComponent: () =>
          import('./components/system-events/system-events.component').then(
            (m) => m.SystemEventsComponent
          ),
      },
      {
        path: 'profiles',
        loadChildren: () => ROUTES_PROFILES,
      },
      {
        path: 'api',
        loadChildren: () => ROUTES_CLIENT_APPS,
      },
      {
        path: 'schema',
        loadComponent: () =>
          import('./components/schema/schema.component').then(
            (m) => m.SchemaComponent
          ),
      },
    ],
    pathMatch: 'prefix',
  },
  {
    path: 'add-user',
    loadComponent: () =>
      import('./pages/add-user/add-user.component').then(
        (m) => m.AddUserComponent
      ),
  },
  {
    path: 'add-api-user',
    loadComponent: () =>
      import('./pages/add-api-user/add-api-user.component').then(
        (m) => m.AddApiUserComponent
      ),
  },
  {
    path: 'add-app',
    loadComponent: () =>
      import('./pages/add-client-app/add-client-app.component').then(
        (m) => m.AddClientAppComponent
      ),
  },
  {
    path: 'add-counter',
    loadComponent: () =>
      import('./pages/add-custom-counter/add-custom-counter.component').then(
        (m) => m.AddCustomCounterComponent
      ),
  },
  {
    path: 'add-working-hours',
    loadComponent: () =>
      import(
        './pages/add-working-hour-profile/add-working-hour-profile.component'
      ).then((m) => m.AddWorkingHoursProfileComponent),
  },
  {
    path: 'add-asset-sharing',
    loadComponent: () =>
      import(
        './pages/add-asset-sharing-profile/add-asset-sharing-profile.component'
      ).then((m) => m.AddAssetSharingProfileComponent),
  },
];
