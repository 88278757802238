import {
  SELECT_ASSET,
  SELECT_ASSET_GROUP,
  SELECT_INVENTORY,
  SELECT_USER_DATA,
} from './../store/selectors/selectors';
import { loadInventory } from './../store/actions/inventory.action';
import { loadWorkingHoursData } from './../store/actions/working-hours.action';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { SYSTEM_OBJECT } from '../schemas/enums/system-object.enum';
import { loadAssetInventoryData } from '../store/actions/assets.action';
import { loadCounterData } from '../store/actions/counter.action';

import { loadDashboardData } from '../store/actions/dashboards.action';
import { loadDeviceData } from '../store/actions/devices.action';
import { loadGeofenceData } from '../store/actions/geofences.action';
import { loadAssetGroupData } from '../store/actions/groups.action';
import { loadingPeopleData } from '../store/actions/people.action';
import { loadPlatformHealthData } from '../store/actions/platform-health-actions.action';

import { loadReportData } from '../store/actions/reports.action';
import { loadRulesData } from '../store/actions/rules.action';
import { loadSecurityGroupData } from '../store/actions/security-groups.action';
import { loadSitesData } from '../store/actions/sites.action';
import { loadUserData } from '../store/actions/user.action';
import { AppState } from '../store/appState';
import { loadAssetsMasterData } from '../store/actions/assets-master.action';
import { loadServicingData } from '../store/actions/servicing.action';
import { loadAssetSharingData } from '../store/actions/asset-sharing-profiles.action';
import { loadHierarchiesData } from '../store/actions/hierarchies.action';
import { combineLatest, takeWhile } from 'rxjs';
import { loadScheduledReports } from '../store/actions/scheduled-reports.action';
import { loadDownloadReportTasks } from '../store/actions/download-report-tasks.action';
import { loadWeatherAlertsData } from '../store/actions/weather-alerts.action';
import { loadRegionsData } from '../store/actions/regions.action';

@Injectable({ providedIn: 'root' })
export class SystemObjectInitialisationService implements OnDestroy {
  systemObjects: string[];
  subs = [];
  constructor(
    private readonly stateStore: Store<AppState> // Inject the store to dispatch actions
  ) {}

  loadData(systemObjects): boolean {
    this.systemObjects = systemObjects;
    this.loadSystemObjects();
    this.loadPefiferals();

    return true;
  }

  loadAssets(): void {
    let userDataAvailable = false;
    combineLatest([
      this.stateStore.select(SELECT_USER_DATA),
      this.stateStore.select(SELECT_ASSET),
    ])
      ?.pipe(takeWhile(() => !userDataAvailable))
      ?.subscribe(([userData, assets]) => {
        if (userData && !assets?.devices) {
          userDataAvailable = true;
          this.stateStore.dispatch(loadAssetInventoryData());
        }
      });
  }

  loadGroups(): void {
    let groupsDataAvailable = false;
    this.subs.push(
      this.stateStore
        .select(SELECT_ASSET_GROUP)
        ?.pipe(takeWhile(() => !groupsDataAvailable))
        ?.subscribe((groups) => {
          if (!groups.assetGroups) {
            groupsDataAvailable = true;
            this.stateStore.dispatch(loadAssetGroupData());
          }
        })
    );
  }

  loadInventoryData(): void {
    let isDataAvailable = false;
    this.subs.push(
      this.stateStore
        .select(SELECT_INVENTORY)
        ?.pipe(takeWhile(() => !isDataAvailable))
        ?.subscribe((inventory) => {
          if (!inventory?.data) {
            isDataAvailable = true;
            this.stateStore.dispatch(loadInventory());
          }
        })
    );
  }

  loadPefiferals(): void {
    if (this.systemObjects?.includes(SYSTEM_OBJECT.PLATFORM_HEALTH_DATA)) {
      this.stateStore.dispatch(loadPlatformHealthData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.CUSTOM_COUNTERS)) {
      this.stateStore.dispatch(loadCounterData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.USERS)) {
      this.stateStore.dispatch(loadUserData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.SECURITY_GROUP)) {
      this.stateStore.dispatch(loadSecurityGroupData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.PEOPLE)) {
      this.stateStore.dispatch(loadingPeopleData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.WORKING_HOURS)) {
      this.stateStore.dispatch(loadWorkingHoursData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.ASSET_SHARING_PROFILES)) {
      this.stateStore.dispatch(loadAssetSharingData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.ASSETS_MASTER_DATA)) {
      this.stateStore.dispatch(loadAssetsMasterData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.SERVICING_DATA)) {
      this.stateStore.dispatch(loadServicingData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.HIERARCHIES_DATA)) {
      this.stateStore.dispatch(loadHierarchiesData({ forceFetch: false }));
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.INVENTORY)) {
      this.loadInventoryData();
    }
  }

  loadSystemObjects(): void {
    if (this.systemObjects?.includes(SYSTEM_OBJECT.REPORTS)) {
      this.stateStore.dispatch(loadReportData());
    }

    if (this.systemObjects?.includes(SYSTEM_OBJECT.SCHEDULED_REPORTS)) {
      this.stateStore.dispatch(loadScheduledReports({ forceFetch: false }));
    }

    if (this.systemObjects?.includes(SYSTEM_OBJECT.DOWNLOAD_REPORT_TASKS)) {
      this.stateStore.dispatch(loadDownloadReportTasks({ forceFetch: false }));
    }

    if (this.systemObjects?.includes(SYSTEM_OBJECT.DASHBOARDS)) {
      this.stateStore.dispatch(loadDashboardData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.RULES)) {
      this.stateStore.dispatch(loadRulesData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.GEOFENCES)) {
      this.stateStore.dispatch(loadGeofenceData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.SITES)) {
      this.stateStore.dispatch(loadSitesData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.ASSET_GROUPS)) {
      this.loadGroups();
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.DEVICES)) {
      this.stateStore.dispatch(loadDeviceData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.ASSETS)) {
      this.loadAssets();
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.WEATHER_ALERTS)) {
      this.stateStore.dispatch(loadWeatherAlertsData());
    }
    if (this.systemObjects?.includes(SYSTEM_OBJECT.REGIONS)) {
      this.stateStore.dispatch(loadRegionsData());
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
