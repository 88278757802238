import { createAction, props } from '@ngrx/store';
import {
  LOAD_REGIONS,
  LOAD_REGIONS_ERROR,
  STORE_REGIONS,
} from './action-types';

export const loadRegionsData = createAction(LOAD_REGIONS);
export const loadRegionsSuccess = createAction(
  STORE_REGIONS,
  props<{ payload: any[] }>()
);
export const loadRegionsFailure = createAction(
  LOAD_REGIONS_ERROR,
  props<{ error: any }>()
);
