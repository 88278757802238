import { Routes } from '@angular/router';

export const ROUTES_REPORTING: Routes = [
  { path: '', redirectTo: 'cards', pathMatch: 'prefix' },
  {
    path: 'cards',
    loadComponent: () =>
      import('./pages/reporting-summary/reporting-summary.component').then(
        (m) => m.ReportingSummaryComponent
      ),
  },
  {
    path: 'list',
    loadComponent: () =>
      import(
        './components/reporting-list-view/reporting-list-view.component'
      ).then((m) => m.ReportingListViewComponent),
  },
  {
    path: ':objectId',

    loadComponent: () =>
      import('./pages/reporting-detail/reporting-detail.component').then(
        (m) => m.ReportingDetailComponent
      ),
    children: [
      { pathMatch: 'prefix', path: '', redirectTo: 'snapshot' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import('./components/report-snapshot/report-snapshot.component').then(
            (m) => m.ReportingSnapshotComponent
          ),
      },
      {
        path: 'downloads',
        loadComponent: () =>
          import('./pages/reporting-download/report-download.component').then(
            (m) => m.ReportDownloadComponent
          ),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import('./components/report-settings/report-settings.component').then(
            (m) => m.ReportSettingsComponent
          ),
      },
      {
        path: 'debug',
        loadComponent: () =>
          import('./components/reporting-debug/reporting-debug.component').then(
            (m) => m.ReportingDebugComponent
          ),
      },
      {
        path: 'schedule',
        loadComponent: () =>
          import(
            './components/schedule-list-view/schedule-list-view.component'
          ).then((m) => m.ScheduleListViewComponent),
      },
    ],
  },
];
