import { ButtonDesignTokens } from '@primeng/themes/types/button';
import { buttonStyles } from './generated/styles';

export const BUTTON_TOKENS = {
  paddingY: '0.5rem',
  colorScheme: {
    light: {
      outlined: {
        primary: {
          borderColor: '{perspio.primaryColor}',
        },
        secondary: {
          borderColor: '{p-button-secondary-color}',
        },
        danger: {
          borderColor: '{p-button-danger-color}',
        },
      },
      text: {
        primary: {
          hoverBackground: '#2196f30a',
        },
      },
    },
  },
  css: () => buttonStyles,
} as ButtonDesignTokens;
