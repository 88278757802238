import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_REGIONS } from '../selectors/selectors';
import {
  loadRegionsData,
  loadRegionsFailure,
  loadRegionsSuccess,
} from '../actions/regions.action';

@Injectable()
export class RegionsEffects {
  constructor(
    public actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}

  loadRegionsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRegionsData),
      withLatestFrom(this.store.select(SELECT_REGIONS)),
      filter(([_action, regionsData]) => {
        return regionsData?.regions === null;
      }),
      switchMap(([_action, _regionsData]) =>
        this.appHttpService.getRegions().pipe(
          map((data) => {
            return loadRegionsSuccess({ payload: data });
          }),
          catchError((error) => of(loadRegionsFailure({ error })))
        )
      )
    )
  );
}
