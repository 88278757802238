import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_DOWNLOAD_REPORT_TASKS } from '../selectors/selectors';
import {
  loadDownloadReportTasks,
  loadDownloadReportTasksFailure,
  loadDownloadReportTasksSuccess,
} from '../actions/download-report-tasks.action';
import { ReportingService } from '@app/perspio/reporting/services/reporting.service';

@Injectable()
export class DownloadReportTasksEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly reportService: ReportingService,
    private readonly store: Store<AppState>
  ) {}

  loadDownloadReportTasksData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDownloadReportTasks),
      withLatestFrom(this.store.select(SELECT_DOWNLOAD_REPORT_TASKS)),
      filter(([action, reports]) => reports === null || action.forceFetch),
      switchMap(([_action, _reports]) =>
        this.reportService.getReportDownLoadTaskData().pipe(
          map((data) => loadDownloadReportTasksSuccess({ payload: data })),
          catchError((error) => of(loadDownloadReportTasksFailure({ error })))
        )
      )
    )
  );
}
