//mypreset.ts
import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';
import { TAG_TOKENS } from './prime-theme/framework/tokens/tag';
import { ACCORDION_TOKENS } from './prime-theme/framework/tokens/accordion';
import { PROGRESSBAR_TOKENS } from './prime-theme/framework/tokens/progressbar';
import { INPUTTEXT_TOKENS } from './prime-theme/framework/tokens/inputtext';
import { INPUTGROUP_TOKENS } from './prime-theme/framework/tokens/inputgroup';
import { DATEPICKER_TOKENS } from './prime-theme/framework/tokens/datepicker';
import { SELECT_TOKENS } from './prime-theme/framework/tokens/select';
import { MULTISELECT_TOKENS } from './prime-theme/framework/tokens/multiselect';
import { COLOUR_SCHEME } from './palette/colour_scheme';
import { AUTOCOMPLETE_TOKENS } from './prime-theme/framework/tokens/autocomplete';
import { TOGGLEBUTTON_TOKENS } from './prime-theme/framework/tokens/togglebutton';
import { TABS_TOKENS } from './prime-theme/framework/tokens/tabs';
import { CHIP_TOKEN } from './prime-theme/framework/tokens/chip';
import { STEPPER_TOKENS } from './prime-theme/framework/tokens/stepper';
import { PERSPIO_TOKENS } from './prime-theme/framework/tokens/perspio';
import { BUTTON_TOKENS } from './prime-theme/framework/tokens/button';
import { TOGGLESWITCH_TOKENS } from './prime-theme/framework/tokens/toggleswitch';
import { CARD_TOKENS } from './prime-theme/framework/tokens/card';
import { MESSAGE_TOKENS } from './prime-theme/framework/tokens/message';
import { BADGE_TOKENS } from './prime-theme/framework/tokens/badge';
import { AVATAR_TOKENS } from './prime-theme/framework/tokens/avatar';

const PERSPIO_PRESET_OVERRIDES = definePreset(Lara, {
  semantic: {
    colorScheme: {
      ...COLOUR_SCHEME,
    },
  },
  components: {
    tag: {
      ...TAG_TOKENS,
    },
    accordion: {
      ...ACCORDION_TOKENS,
    },
    progressbar: {
      ...PROGRESSBAR_TOKENS,
    },
    inputtext: {
      ...INPUTTEXT_TOKENS,
    },
    inputgroup: {
      ...INPUTGROUP_TOKENS,
    },
    datepicker: {
      ...DATEPICKER_TOKENS,
    },
    button: {
      ...BUTTON_TOKENS,
    },
    autocomplete: {
      ...AUTOCOMPLETE_TOKENS,
    },
    togglebutton: {
      ...TOGGLEBUTTON_TOKENS,
    },
    tabs: {
      ...TABS_TOKENS,
    },
    select: {
      ...SELECT_TOKENS,
    },
    chip: {
      ...CHIP_TOKEN,
    },
    multiselect: {
      ...MULTISELECT_TOKENS,
    },
    stepper: {
      ...STEPPER_TOKENS,
    },
    toggleswitch: {
      ...TOGGLESWITCH_TOKENS,
    },
    card: {
      ...CARD_TOKENS,
    },
    message: {
      ...MESSAGE_TOKENS,
    },
    badge: {
      ...BADGE_TOKENS,
    },
    avatar: {
      ...AVATAR_TOKENS,
    },
  },
  extend: {
    perspio: {
      ...PERSPIO_TOKENS,
    },
  },
});

export { PERSPIO_PRESET_OVERRIDES };
