import { AvatarDesignTokens } from '@primeng/themes/types/avatar';
import { avatarStyles } from './generated/styles';

export const AVATAR_TOKENS = {
  background: '{perspio.main.color}',
  fontSize: '0.5rem',
  height: '1.6rem',
  width: '1.6rem',
  css: () => avatarStyles,
} as AvatarDesignTokens;
