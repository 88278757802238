import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../store/appState';
import { filter, map, tap } from 'rxjs/operators';
import { AppInitialiseService } from './app-initialize.service';
import { RoleDefinitionMock } from 'mocks/role-definition.mock';
import { SELECT_ROLE_DEFINITIONS } from '../store/selectors/selectors';

@Injectable({
  providedIn: 'root',
})
export class RouteAuthGuard {
  constructor(
    public router: Router,
    private readonly stateStore: Store<AppState>,
    private readonly appInitialiseService: AppInitialiseService
  ) {}

  roleDefinitionSelector = SELECT_ROLE_DEFINITIONS;
  roleDefinitionDefaults = RoleDefinitionMock;

  getFromStoreOrAPI(): Observable<any> {
    return this.stateStore.select(this.roleDefinitionSelector).pipe(
      tap((data) => {
        if (!data?.data) {
          this.appInitialiseService.initialise();
        }
      }),
      filter((data: any) => {
        return data.data != null;
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const routeData = route?.data;
    return this.getFromStoreOrAPI().pipe(
      map((data) => {
        let module;

        // for submodules
        if (routeData?.subModule) {
          const subModules = data?.data?.modules
            ?.filter((mod) => mod?.id === routeData?.module)
            ?.shift()?.subModules;

          // check if the access API has submodule data, else default the access levels
          if (subModules?.length > 0) {
            module = subModules
              ?.filter((mod) => mod?.id === routeData?.subModule)
              ?.shift();
          } else {
            module = this.roleDefinitionDefaults?.data?.modules
              ?.filter((mod) => mod?.id === routeData?.module)
              ?.shift()
              ?.subModules?.filter((mod) => mod?.id === routeData?.subModule)
              ?.shift();
          }
        }
        // for primary modules
        else {
          module = data?.data?.modules
            ?.filter((mod) => mod?.id === routeData?.module)
            ?.shift();
        }

        if (!module?.enabled) {
          this.router.navigate(['home']);
        }

        return module.enabled;
      })
    );
  }
}
