import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_RULE, SELECT_WEATHER_ALERTS } from '../selectors/selectors';
import {
  loadWeatherAlertsData,
  loadWeatherAlertsFailure,
  loadWeatherAlertsSuccess,
} from '../actions/weather-alerts.action';

@Injectable()
export class WeatherAlertsEffects {
  constructor(
    public actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}

  loadWeatherAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWeatherAlertsData),
      withLatestFrom(this.store.select(SELECT_WEATHER_ALERTS)),
      filter(([_action, weatherData]) => {
        return weatherData?.weatherAlerts === null;
      }),
      switchMap(([_action, _weatherData]) =>
        this.appHttpService.getWeatherAlerts().pipe(
          map((data) => {
            return loadWeatherAlertsSuccess({ payload: data });
          }),
          catchError((error) => of(loadWeatherAlertsFailure({ error })))
        )
      )
    )
  );
}
