import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // do not overwrite authorization token set by signalR negotiate
    if (!request.headers.get('authorization')) {
      const tennantId = this.authService.getTenantId();
      const isSharedApp = window.location.pathname.includes('share');

      request = request.clone({
        headers: request.headers
          .set('tid', tennantId)
          .set(
            'Ocp-Apim-Subscription-Key',
            isSharedApp ? environment.publicApiKey : environment.apiKey
          ),
      });
    }

    return next.handle(request);
  }
}
